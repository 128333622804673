import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { TeamOutlined } from "@ant-design/icons";



export default function Seo() {

  return (
    <><><Header />
      <div className="worksArea">
        <a href="./logo.pdf"></a>
        <div className='workPage'>
          <h2>SEO ÇALIŞMALARI</h2>
          <h4>Blue Creative Agency</h4>
        </div>
        <div className="container">
          <div className="worksCard">
            <div className="worksCardDescription">
              <div className="worksCardDescriptionWidth">
                <h2>SEO ÇALIŞMALARI</h2>
                <p>‘<strong>Siz</strong>’i dijital ortamlarda fark edilir kılabilmek için çalışıyoruz.</p>
              </div>
            </div>
            <div className="worksCardİmages">
              <img src={require('../../images/works/seo300.webp')} />
            </div>
          </div>
          <div className="worksCardDoing">
            <div className="worksCardDoingDescription">
              <div>
                <h3>Neler Yapıyoruz ?</h3>
                <ul>
                  <li>
                    SWOT Analizi : Rakiplerinize kıyasla Güçlü, Zayıf, Fırsat ve Tehditlerinizi analiz ederiz.
                  </li>
                  <li>
                    Anahtar Kelimeler : Sizi en önlerde çıkartacak anahtar kelimelerinizi analizimize göre belirleyip kullanırız.
                  </li>
                  <li>
                    Metin Yazarlığı : SEO'ya uygun bir biçimde metinlerinizi yazarız.
                  </li>
                  <li>
                    Reklam : Bütçenizi doğru kullanarak en geniş noktalara ulaşmanızı sağlarız.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};