import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';



export default function Works() {

    return (
        <><><Header />
            <div className="worksPage">
                <Row align="middle">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/sosyalmedya300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescription'>
                            <a href="/sosyal-medya"><h2>SOSYAL MEDYA YÖNETİMİ</h2></a>
                            <div className="button_container">
                                <button className="btn"><a href="/sosyal-medya">İncelemek İçin Tıklayınız!</a></button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row align="middle" className="reverse">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/website300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescriptions'>
                            <a href="/izmit-website-tasarımları"><h2>WEB SİTE TASARIMLARI</h2></a>
                            <div className="button_container">
                                <button className="btn"><a href="/izmit-website-tasarımları">İncelemek İçin Tıklayınız!</a></button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/kartvizit300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescription'>
                            <a href="/kurumsal-kimlik"><h2>KURUMSAL KİMLİK OLUŞTURMA</h2></a>
                            <div className="button_container">
                                <button className="btn"><a href="/kurumsal-kimlik">İncelemek İçin Tıklayınız!</a></button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row align="middle" className="reverse">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/metin300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescriptions'>
                            <h2>METİN YAZARLIĞI</h2>
                            {/* <div className="button_container">
                                <button className="btn"><a href="/metin-yazarliği">İncelemek İçin Tıklayınız!</a></button>
                            </div> */}
                        </div>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/video300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescription'>
                            <h2>VİDEO OLUŞTURMA</h2>
                            <p>Markanızı reklam ve sosyal medya videoları ile rakiplerinizden ayrıştırıyoruz.</p>
                        </div>
                    </Col>
                </Row>
                <Row align="middle" className="reverse">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/seo300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescriptions'>
                            <a href="/seo-calismalari"><h2>SEO ÇALIŞMALARI</h2></a>
                            <div className="button_container">
                                <button className="btn"><a href="/seo-calismalari">İncelemek İçin Tıklayınız!</a></button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/reklam300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescription'>
                            <a href="/reklam-hizmetleri"><h2>REKLAM HİZMETLERİ</h2></a>
                            <div className="button_container">
                                <button className="btn"><a href="/reklam-hizmetleri">İncelemek İçin Tıklayınız!</a></button>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
                <Row align="middle" className="reverse">
                    <Col lg={12} md={12} sm={24} xs={24} >
                        <div className='worksPageİmages'>
                            <img src={require('../../images/works/tabela300.webp')} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className='worksPageDescriptions'>
                            <h2>AFİŞ TABELA</h2>
                            <p>Markanıza en uygun afiş ve tasarımları yapıyoruz.</p>
                        </div>
                    </Col>
                </Row>


            </div></><Footer /></>
    );
};