import { Button, Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { CommentOutlined, HeartOutlined, MessageOutlined, NotificationOutlined, TeamOutlined } from "@ant-design/icons";



export default function WhoAreWe() {

  return (
    <><><Header />
      <div>
        <div className='detailPageİmages'>
          <img className='banner' src={require('../../images/slider/socialMedia.webp')} />
          <img className='mobileBanner' src={require('../../images/works/sosyalmedya300.webp')} />
        </div>
        <div className="cardArea">
          <div className="card">
            <div className="cardIcon" style={{ width: '25%', height: '100%' }}>
              <TeamOutlined />
            </div>
            <div className="cardDescription">
              <div>
                <h2>BİZ KİMİZ?</h2>
                <p>Biz, “Siz kimsiniz?” sorusunun yanıtını verebilmek için yola çıkan güçlü bir ekibiz. Bizim için bizden çok, ‘siz’in kim olduğunuz önemli. Çünkü siz, gücünüzü fark ettiğinizde ‘siz’ olacaksınız. Aradığınız ‘siz’i bulabilmek için doğru yerdesiniz.
                  Bue Creative Agency, size güçlü bir kurumsal kimlik oluşturmak, etkin bir sosyal medya çalışması yapmak, uygun bir bütçe ile doğru sonuçlar alabilmeniz için kuruldu.</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="cardIcon" style={{ width: '25%', height: '100%' }}>
            <NotificationOutlined />
            </div>
            <div className="cardDescription">
              <div>
                <h2>HAKKIMIZDA</h2>
                <p style={{paddingBottom:'10px'}}>Blue Creative Agency, 2022 yılında Kocaeli/İzmit'te kurulmuş kreatif bir reklam ajansıdır.</p>
                <p>Sosyal medya yönetimi, video oluşturma, reklam hizmetleri, metin yazarlığı, SEO çalışmaları, web site tasarımları, kurumsal kimlik oluşturma, afiş tabela tasarımları başta olmak üzere, ‘siz’i güçlü kılacak hizmetleri hayata geçiriyoruz. Blue Creative Agency olarak attığınız her adımı birlikte planlıyoruz.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="cardIcon" style={{ width: '25%', height: '100%' }}>
              <HeartOutlined />
            </div>
            <div className="cardDescription">
              <div>
                <h2>ARADIĞINIZ 'SİZ' BURADA!</h2>
                <p>'Siz'i ve markanızı en iyi yerlere taşımak ve Sizi sektörün öne çıkanlarından yapmaya Blue Creative Agency ekibi olarak hazırız.</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="cardIcon" style={{ width: '25%', height: '100%' }}>
            <CommentOutlined />
            </div>
            <div className="cardDescription">
              <div>
                <h2>'SİZ'İN İÇİN EN İYİSİNİ YAPALIM!</h2>
                <p>Bizimle iletişime geçin. Uzmanlığımızı ve yeteneğimizi, ürün ve hizmetleriniz ile birleştirelim.</p>
              </div>
              <div className="button_container">
                <button className="btn"><a href="/iletisim">Size Ulaşalım!</a></button>
              </div>
            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};