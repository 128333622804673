import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';



export default function References() {

  return (
    <><><Header />
      <div>
        <div className='whoAreWeDescription'>
          <h2>REFERANSLARIMIZ</h2>
        </div>
      </div></><Footer /></>
  );
};