import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { TeamOutlined } from "@ant-design/icons";



export default function TextWriter() {

  return (
    <><><Header />
      <div className="worksArea">
        <a href="./logo.pdf"></a>
        <div className='workPage'>
         <h2>METİN YAZARLIĞI</h2>
         <h4>Blue Creative Agency</h4>
        </div>
        <div className="container">
          <div className="worksCard">
            <div className="worksCardDescription">
              <div className="worksCardDescriptionWidth">
                <h2>METİN YAZARLIĞI</h2>
                <p>Anlatacağınız hikâye ‘<strong>Siz</strong>’i güçlü bir markaya dönüştürür. Ekibimiz yazacağı hikâye ile size güç katacak.</p>
              </div>
            </div>
            <div className="worksCardİmages"> 
              <img src={require('../../images/works/sosyalmedya300.webp')} />
            </div>
          </div>
          <div className="worksCardDoing">
            <div className="worksCardDoingDescription">
              <div>
                <h3>Neler Yapıyoruz ?</h3>
                <ul>
                  <li>
                    'Siz'i en iyi anlatan hikayeleri yazıyoruz. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="worksCardİmages">

            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};