import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { TeamOutlined } from "@ant-design/icons";



export default function WebSite() {

  return (
    <><><Header />
      <div className="worksArea">
        <a href="./logo.pdf"></a>
        <div className='workPage'>
         <h2>WEB SİTE TASARIMLARI</h2>
         <h4>Blue Creative Agency</h4>
        </div>
        <div className="container">
          <div className="worksCard">
            <div className="worksCardDescription">
              <div className="worksCardDescriptionWidth">
                <h2>WEB SİTE TASARIMLARI</h2>
                <p>'Siz'e özel ihtiyaçlarınız ve talepleriniz doğrultusunda sitenizi tasarlarız. Web siteniz 'Siz'in dijital dünyadaki kimliğinizdir.  </p>
              </div>
            </div>
            <div className="worksCardİmages"> 
              <img src={require('../../images/works/website300.webp')} />
            </div>
          </div>
          <div className="worksCardDoing">
            <div className="worksCardDoingDescription">
              <div>
                <h3>Neler Yapıyoruz ?</h3>
                <ul>
                  <li>
                    SWOT Analizi : Rakiplerinize kıyasla Güçlü, Zayıf, Fırsat ve Tehditlerinizi masaya yatırır planlama aşamamızda bunları göz önünde bulundururuz.
                  </li>
                  <li>
                    Planlama Aşaması : İhtiyaçlarınız ve İstekleriniz doğrultusunda Analizinizden çıkan sonuçlarla sitenizin tasarımını ve planını yaparız. Burdan çıkan sonuçlara bakarak diğer maddelere devam ederiz.
                  </li>
                  <li>
                    Alan Adı : 'Siz'e özel Alan adınızı alarak işlemlere başlıyoruz.
                  </li>
                  <li>
                    Tasarım Süreci : Plana uygun bir şekilde, 'Siz'e özel web ve mobil site tasarımlarınızı yapıyoruz.
                  </li>
                  <li>
                    Kodlama : Siz tasarımı beğendikten sonra gerisini yazılım ekibimize bırakıyoruz. 
                  </li>
                  <li>
                    Metin Yazarlığı : 'Siz'i güçlü kılacak iddialı yazılarla Web sitenizi farklı kılıyoruz.
                  </li>
                </ul>
              </div>
            </div>
            <div className="worksCardİmages">

            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};