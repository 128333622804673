import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { TeamOutlined } from "@ant-design/icons";



export default function SocialMedia() {

  return (
    <><><Header />
      <div className="worksArea">
        <a href="./logo.pdf"></a>
        <div className='workPage'>
         <h2>SOSYAL MEDYA YÖNETİMİ</h2>
         <h4>Blue Creative Agency</h4>
        </div>
        <div className="container">
          <div className="worksCard">
            <div className="worksCardDescription">
              <div className="worksCardDescriptionWidth">
                <h2>SOSYAL MEDYA YÖNETİMİ</h2>
                <p>Sosyal medya ‘siz’in için stratejik bir alan. Ekibimiz ‘siz’in için içerikler üretiyor, tüm sosyal medya platformlarının çalışma algoritmasına göre ‘siz’i farklılaştırıyor ve öne çıkarıyoruz. Sosyal medya yönetimi sadece paylaşım yapmak değildir. Yapılacak olan paylaşımların hedef kitleye hitap etmesi ve ilgisini çekmesi oldukça büyük bir öneme sahiptir. Yanlış yapılan sosyal medya yönetimi hedef kitlenizin ilgisini kaybetmesine sebep olabilir ve hedef kitlenize zarar verebilir.</p>
              </div>
            </div>
            <div className="worksCardİmages"> 
              <img src={require('../../images/works/sosyalmedya300.webp')} />
            </div>
          </div>
          <div className="worksCardDoing">
            <div className="worksCardDoingDescription">
              <div>
                <h3>Neler Yapıyoruz ?</h3>
                <ul>
                  <li>
                    Hedef Kitle Analizi : Sektörel planlama ve değerlendirme yaparak hedef kitlenizi ve 'Siz'i analiz eder planlamalarımıza bu doğrultuda devam ederiz.
                  </li>
                  <li>
                    Yönetim Stratejisi : Hedef Kitle Analizine ve Siz'in ihtiyaç ve isteklerinize göre 'Siz'e özel bir yönetim stratejisi hazırlarız.
                  </li>
                  <li>
                    İçerik Üretimi : Hazırladığımız Yönetim Stratejisi doğrultusunda içeriklerinizi üretiriz.
                  </li>
                  <li>
                    Metin Yazarlığı : 'Siz'e özel hazırladığımız içeriklere ve reklam stratejisine uygun şekilde paylaşımlarınızın metinlerini yazarız.
                  </li>
                  <li>
                    Reklam : Bütçenizi doğru kullanarak en geniş noktalara ulaşmanızı sağlarız.
                  </li>
                  <li>
                    Raporlama : Sosyal medya yönetiminize ve markanıza ait aylık olarak raporlar gönderilir. Bu raporlarda paylaşımlarınız, erişim değerleri ve reklam yönetim sonuçlarına ait raporlar sunulmaktadır.
                  </li>
                </ul>
              </div>
            </div>
            <div className="worksCardİmages">

            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};