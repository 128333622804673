import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { TeamOutlined } from "@ant-design/icons";



export default function Ads() {

  return (
    <><><Header />
      <div className="worksArea">
        <a href="./logo.pdf"></a>
        <div className='workPage'>
          <h2>REKLAM HİZMETLERİ</h2>
          <h4>Blue Creative Agency</h4>
        </div>
        <div className="container">
          <div className="worksCard">
            <div className="worksCardDescription">
              <div className="worksCardDescriptionWidth">
                <h2>REKLAM HİZMETLERİ</h2>
                <p>Bütçenizi doğru kullanarak en geniş noktalara ulaşmanızı sağlıyoruz.</p>
              </div>
            </div>
            <div className="worksCardİmages">
              <img src={require('../../images/works/reklam300.webp')} />
            </div>
          </div>
          <div className="worksCardDoing">
            <div className="worksCardDoingDescription">
              <div>
                <h3>Neler Yapıyoruz ?</h3>
                <ul>
                  <li>
                    SWOT Analizi : Rakiplerinize kıyasla Güçlü, Zayıf, Fırsat ve Tehditlerinizi analiz ederiz.
                  </li>
                  <li>
                    Anahtar Kelimeler : Sizi en önlerde çıkartacak reklam için en uygun anahtar kelimeleri analizimize göre belirleyip kullanırız.
                  </li>
                  <li>
                    Bütçe : Bütçe planımızı sizin belirlediğiniz doğrultuda kullanarak en geniş noktalara ulaşmanızı sağlarız.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};