import { Button, Col, Form, Input, Menu, message, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import './style.css';
import { useForm, ValidationError } from '@formspree/react';
import TextArea from "antd/es/input/TextArea";
import React from "react";
import GoogleMapReact from 'google-map-react';




export default function Contact() {
    const [state, handleSubmit] = useForm("xknazpjd");
    if (state.succeeded === true) {
        message.success('Bizimle iletişime geçtiğiniz için teşekkürler!', 4);
    }

    // const defaultProps = {
    //     center: {
    //         lat: 10.99835602,
    //         lng: 77.01502627
    //     },
    //     zoom: 11
    // };

    return (
        <><><Header />
         <div className='detailPageİmages'>
          <img className='banner' src={require('../../images/slider/siteee-02.webp')} />
          <img className='mobileBanner' src={require('../../images/works/sosyalmedya300.webp')} />
        </div>
            <div className='contactArea'>
                <h3>Bizimle İletişime Geçin!</h3>
                <form onSubmit={handleSubmit}>
                            <div className="contactFormArea">
                                <input
                                    id="name"
                                    type="name"
                                    name="name"
                                    required
                                    placeholder="İsminiz"
                                />
                                <ValidationError
                                    prefix="Email"
                                    field="email"
                                    errors={state.errors}
                                />
                                <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    required
                                    placeholder="Mail Adresiniz"
                                />
                                <ValidationError
                                    prefix="Email"
                                    field="email"
                                    errors={state.errors}
                                />
                                <textarea
                                    id="message"
                                    name="message"
                                    required
                                    placeholder="Mesajınız.."
                                />
                                <ValidationError
                                    prefix="Message"
                                    field="message"
                                    errors={state.errors}
                                />
                            </div>
                       
                            <div className='contactButton'>
                                <button type="submit" disabled={state.submitting}>
                                    <span>
                                        Gönder
                                    </span>
                                </button>
                            </div>
                </form>
            </div>
            {/* <div style={{ height: '50vh', width: '35%', justifyContent: 'flex-end' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                </GoogleMapReact>
            </div> */}
        </><Footer /></>
    );
};