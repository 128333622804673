import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import React, { useState } from "react";
import '../index.css';
import SwiperCore, { Mousewheel, Controller, Navigation } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";
import { Dropdown, Space } from 'antd';


SwiperCore.use([Mousewheel, Controller]);


export default function Home() {
  const [isActive, setActive] = useState(false);
  const [current, setCurrent] = useState(0);

  const swiper = useSwiper();

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className='ana'>
      <div className='logo'>
        <img src={require('../images/slider/bca_logo_yatay-background.png')} alt="" />
      </div>
      <div className={isActive ? 'active' : 'not'} >
        <div>
          <svg className="ham ham3" viewBox="0 0 100 100" width="80" onClick={toggleClass}>
            <path
              className="line top"
              d="m 70,33 h -40 c -11.092231,0 11.883874,13.496726 -3.420361,12.956839 -0.962502,-2.089471 -2.222071,-3.282996 -4.545687,-3.282996 -2.323616,0 -5.113897,2.622752 -5.113897,7.071068 0,4.448316 2.080609,7.007933 5.555839,7.007933 2.401943,0 2.96769,-1.283974 4.166879,-3.282995 2.209342,0.273823 4.031294,1.642466 5.857227,-0.252538 v -13.005715 16.288404 h 7.653568" />
            <path
              className="line middle"
              d="m 70,50 h -40 c -5.6862,0 -8.534259,5.373483 -8.534259,11.551069 0,7.187738 3.499166,10.922274 13.131984,10.922274 11.021777,0 7.022787,-15.773343 15.531095,-15.773343 3.268142,0 5.177031,-2.159429 5.177031,-6.7 0,-4.540571 -1.766442,-7.33533 -5.087851,-7.326157 -3.321409,0.0092 -5.771288,2.789632 -5.771288,7.326157 0,4.536525 2.478983,6.805271 5.771288,6.7" />
            <path
              className="line bottom"
              d="m 70,67 h -40 c 0,0 -3.680675,0.737051 -3.660714,-3.517857 0.02541,-5.415597 3.391687,-10.357143 10.982142,-10.357143 4.048418,0 17.88928,0.178572 23.482143,0.178572 0,2.563604 2.451177,3.403635 4.642857,3.392857 2.19168,-0.01078 4.373905,-1.369814 4.375,-3.392857 0.0011,-2.023043 -1.924401,-2.589191 -4.553571,-4.107143 -2.62917,-1.517952 -4.196429,-1.799562 -4.196429,-3.660714 0,-1.861153 2.442181,-3.118811 4.196429,-3.035715 1.754248,0.0831 4.375,0.890841 4.375,3.125 2.628634,0 6.160714,0.267857 6.160714,0.267857 l -0.178571,-2.946428 10.178571,0 -10.178571,0 v 6.696428 l 8.928571,0 -8.928571,0 v 7.142858 l 10.178571,0 -10.178571,0" />
          </svg>
        </div>
        <div className='hiddenMenu'>
          <ul>
            <li><a href="/biz-kimiz">Biz Kimiz</a></li>
            <li><a href="/iletisim">İletişim</a></li>
            {/* <li><a href="/referanslar">Referanslar</a></li> */}
            <li><a href="/neler-yapıyoruz">Siz’in İçin Yapacaklarımız</a></li>
            {/* <li> 
            <Dropdown 
            menu={{items}}
            arrow={{
              pointAtCenter: true,
            }}
            placement="bottom">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                Siz’in İçin Yapacaklarımız
                </Space>
              </a>
            </Dropdown>
            </li> */}
          </ul>
        </div>
      </div>
      <Swiper
        // spaceBetween={50}
        slidesPerView={1}
        navigation={true}
        modules={[Navigation]}
        mousewheel
        onSlideChange={(e) => setTimeout(() => { setCurrent(e.activeIndex) },3000)}
        onSwiper={(swiper: any) => console.log(swiper)}
      >
        <SwiperSlide className={current === 0 ? `data` : 'asdas'}>
          <img className='banner' src={require('../images/slider/BANNER-01.jpg')} alt="blue-creative-agency" />
          <img className='mobileBanner' src={require('../images/mobileBanner/mobile1.webp')} alt="blue-creative-agency-banner-mobile-1" />
          <div className='patternsDesktop'>
            <div className="patterns">
              <svg width="100%" height="100%">
                <defs>
                  <pattern id="polka-dots" x="0" y="0" width="100" height="100"
                    patternUnits="userSpaceOnUse">
                    <circle fill="#be9ddf" cx="25" cy="25" r="3"></circle>
                  </pattern>
                </defs>
                <text x="50%" y="52%" text-anchor="middle"  >
                  ARADIĞINIZ 'SİZ' BURADA!
                </text>
              </svg>
            </div>
          </div>
          <div className='patternsMobile'>
            <div className="patterns">
              <svg width="100%" height="100%">
                <defs>
                  <pattern id="polka-dots" x="0" y="0" width="100" height="100"
                    patternUnits="userSpaceOnUse">
                    <circle fill="#be9ddf" cx="25" cy="25" r="3"></circle>
                  </pattern>
                </defs>

                <text x="50%" y="40%" text-anchor="middle"  >
                  ARADIĞINIZ
                </text>
                <text x="50%" y="50%" text-anchor="middle"  >
                  'SİZ'
                </text>
                <text x="50%" y="60%" text-anchor="middle"  >
                  BURADA!
                </text>

              </svg>
            </div>
          </div>
          {/* <h3>ARADIĞINIZ 'SİZ' BURADA!</h3> */}
        </SwiperSlide>
        <SwiperSlide>
          <img className='banner' src={require('../images/slider/BANNER-02.jpg')} alt="blue-creative-agency" />
          <img className='mobileBanner' src={require('../images/mobileBanner/mobile2.webp')} alt="blue-creative-agency-banner-mobile-2" />
          <h3><a href="/biz-kimiz">BİZ KİMİZ</a></h3>
        </SwiperSlide>
        {/* <SwiperSlide>
          <img className='banner' src={require('../images/slider/BANNER-03.jpg')} alt="blue-creative-agency" />
          <img className='mobileBanner' src={require('../images/mobileBanner/mobile3.webp')} alt="blue-creative-agency-banner-mobile-3" />
          <h3> <a href="/hakkımızda">HAKKIMIZDA</a></h3>
        </SwiperSlide> */}
        <SwiperSlide>
          <img className='banner' src={require('../images/slider/BANNER-04.jpg')} alt="blue-creative-agency" />
          <img className='mobileBanner' src={require('../images/mobileBanner/mobile3.webp')} alt="blue-creative-agency-banner-mobile-4" />
          <h3> <a href="/iletisim">İLETİŞİM</a></h3>
        </SwiperSlide>
        <SwiperSlide>
          <img className='banner' src={require('../images/slider/BANNER-05.jpg')} alt="blue-creative-agency" />
          <img className='mobileBanner' src={require('../images/mobileBanner/mobile5.webp')} alt="blue-creative-agency-banner-mobile-5" />
          <h3> <a href="/neler-yapıyoruz" className='ss'>'SİZ'İN İÇİN YAPACAKLARIMIZ</a></h3>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

// 1- Biz kimiz '?' +
// 2- Yazılara line-hegt ver font büyüt
// 3- header font kontrol et değiş +bold
// 4- açıklama yazıları gri olabilir
// 5- seo reklam hizmetleri kısmı fotolar değişcek
// 6- görseller webp formatında olmalı
// 7- Anasayfa yazıları tam gözükmüyor özellikle mobilde
// 8- Router isimleri neler-yapıyoruz düzelt
