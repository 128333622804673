import React from "react";
import "../src/fonts/Montserrat-Medium.ttf"
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/index.tsx'
import WhoAreWe from './pages/whoAreWe/index.tsx'
import Contact from './pages/contact/index.tsx'
import References from './pages/references/index.tsx'
import Works from "./pages/works";
import SocialMedia from './pages/works/socialMedia'
import WebSite from './pages/works/webSite'
import İdentity from './pages/works/identity'
import TextWriter from './pages/works/textWriter'
import Seo from './pages/works/seo'
import Ads from './pages/works/ads'


createRoot(document.getElementById("root")).render(
  <Router>
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/biz-kimiz" component={WhoAreWe} />
      <Route path="/iletisim" component={Contact} />
      <Route path="/referanslar" component={References} />
      <Route path="/neler-yapıyoruz" component={Works} />
      <Route path="/sosyal-medya" component={SocialMedia} />
      <Route path="/izmit-website-tasarımları" component={WebSite} />
      <Route path="/kurumsal-kimlik" component={İdentity} />
      <Route path="/metin-yazarliği" component={TextWriter} />
      <Route path="/seo-calismalari" component={Seo} />
      <Route path="/reklam-hizmetleri" component={Ads} />
      <Route path="/izmit-drone-cekimleri" component={Ads} />
    </Switch>
  </Router>
);