import { FacebookOutlined, GoogleOutlined, InstagramOutlined, LinkedinOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useState } from "react";
import '../index.css';
import 'animate.css';

export default function Footer() {
    return (
        <div className='footer'>

            <div className='addressArea'>
                <div className='address'>
                <a target="_blank" href="https://www.google.com/maps/place/Blue+Creative+Agency/@40.7626275,29.9468075,17z/data=!4m6!3m5!1s0x14cb4fda47dd8c25:0x2dd859b853674f5f!8m2!3d40.7621115!4d29.9507611!16s%2Fg%2F11tkbp5wnj">Körfez, Şht. Remzi Cd., 41040 İzmit/Kocaeli</a> 
                </div>
                <div className='mailAddress'>
                    <a href="mailto:info@kiyireklamcilik.com">info@kiyireklamcilik.com</a>
                </div>
            </div>
            <div className='socialMediaButtons'>
                <ul>
                    <li><a target="_blank" href="https://www.instagram.com/bluecreative.agency/"><InstagramOutlined /></a></li>
                    <li><a target="_blank" href="https://www.linkedin.com/in/blue-creative-agency-99526a258/"><LinkedinOutlined /></a></li>
                    <li><a target="_blank" href="https://www.facebook.com/BlueCreativeAgencyy"><FacebookOutlined /> </a></li>
                    <li><a target="_blank" href="https://www.youtube.com/@BlueCreativeAgency"><YoutubeOutlined /></a></li>
                    <li><a target="_blank" href="https://www.google.com/maps/place/Blue+Creative+Agency/@40.7626275,29.9468075,17z/data=!4m6!3m5!1s0x14cb4fda47dd8c25:0x2dd859b853674f5f!8m2!3d40.7621115!4d29.9507611!16s%2Fg%2F11tkbp5wnj"><GoogleOutlined /></a></li>
                </ul>
            </div>

        </div>
    );
}