import { Col, Menu, Row } from "antd";
import Header from "src/component/header";
import Footer from "src/component/footer";
import '../../index.css';
import './style.css';
import { TeamOutlined } from "@ant-design/icons";



export default function İdentity() {

  return (
    <><><Header />
      <div className="worksArea">
        <a href="./logo.pdf"></a>
        <div className='workPage'>
         <h2>KURUMSAL KİMLİK OLUŞTURMA</h2>
         <h4>Blue Creative Agency</h4>
        </div>
        <div className="container">
          <div className="worksCard">
            <div className="worksCardDescription">
              <div className="worksCardDescriptionWidth">
                <h2>KURUMSAL KİMLİK OLUŞTURMA</h2>
                <p>’Sizi ‘siz’ yapan kurumsal kimliğiniz. ‘Siz’i birlikte buluyoruz.</p>
              </div>
            </div>
            <div className="worksCardİmages"> 
              <img src={require('../../images/works/kartvizit300.webp')} />
            </div>
          </div>
          <div className="worksCardDoing">
            <div className="worksCardDoingDescription">
              <div>
                <h3>Kurumsal Kimlik Nedir ?</h3>
                <ul>
                  <li>
                  Kurumsal Kimlik 'Siz'in Dijital dünyadaki imzanızdır. Nasıl bizlerin giyim tarzı, yaşama tarzı 
                  varsa Kurumumuzunda kendine özel tarzı, rengi olmalıdır. Bu Kurumsallık açısından çok önemlidir. İnsanlar sizi artık
                  bu kimlikle tanıyıp kafalarında bağdaşlaştıracaklardır.
                  </li>
                </ul>
              </div>
              <div>
                <h3>Kurumsal Kimlik Neleri Kapsar ?</h3>
                <ul>
                  <li>
                  Bu yaptığınız işe firmanıza göre değişkenlik gösterebilir. Logo, ürün ambalajları, tabelalar, 
                  antetli kağıtlar, zarflar, dosyalar, kartvizitler, faturalar, araç giydirme, web sitesi, broşürler ve takvimler gibi 
                  bir çok şey girebilir.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div></><Footer /></>
  );
};